export const conformityLayers: any = [
    'conformite_conforme',
    'conformite_nonConforme',
    'conformite_unknown',
];

export const wfsPatrimoineLayers: any = [
    'A_POMPAG_point',
    'A_DEVERS_point',
    'A_TRAPHY_point',
    'A_CAPTEU_point',
    'A_CONDEB_point',
    'A_CHASSE_point',
    'T_SITE_polygon',
    'A_STEP_point',
    'A_VENTOU_point',
    'A_BASSIN_point',
    'A_BOUCHE_point',
];

export const mixPatrimoineLayers: any = [
    'A_COLLEC_line',
    'A_BRANCH_line',
    'A_REGARD_point',
    'A_REGBRA_point',
    'A_RACCOR_point',
    'T_PLT_point',
];

export const geoserver1Layers: any = [
    // 'A_COLLEC_line',
    // 'A_BRANCH_line',
];

export const geoserver2Layers: any = [
    'A_BRANCH_line',
    'A_REGARD_point',
    'A_REGBRA_point',
];

export const geoserver3Layers: any = [
    'A_COLLEC_line',
    'A_POMPAG_point',
    'A_DEVERS_point',
    'A_TRAPHY_point',
    'A_CAPTEU_point',
    'A_CONDEB_point',
    'A_CHASSE_point',
    'T_SITE_polygon',
    'A_STEP_point',
    'A_VENTOU_point',
    'A_BASSIN_point',
    'A_BOUCHE_point',
    'T_PLT_point',
];

export const geoserver4Layers: any = [
    'A_RACCOR_point',
    'F_COMMUN_polygon',
    'T_DOCUM_polygon',
    'A_BASVER_polygon',
    'A_CURAGE_polygon',
    'A_ITV_polygon',
    'A_AXEFOS_line',
    'A_EMPOUV_polygon',
    'A_SECOUL_point',
    'A_SERVIT_polygon',
];