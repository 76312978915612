import React, {ReactElement, useState} from "react";
import {Accordion, Card, Table} from "react-bootstrap";
import "./detail.scss";
import {SzIcon} from "@suezenv/react-theme-components";
import {useTranslation} from "react-i18next";

interface IDetailsTable {
  values: string[][];
}

const DetailsTableAccordion = (props: IDetailsTable): ReactElement => {
    const [detailOpen, setDetailOpen] = useState<boolean>(true);
    const [pltOpen, setPltOpen] = useState<boolean>(true);
    const { t } = useTranslation();

    return (
    <>
        <Accordion defaultActiveKey="0">
            <Card>
                <Accordion.Toggle
                    as={Card.Header}
                    eventKey="0"
                    onClick={() => setDetailOpen(!detailOpen)}
                >
                    {t("asset:synthesis.details")}
                    {detailOpen ?
                        <SzIcon variant="bold" icon="arrow-up-1" /> :
                        <SzIcon variant="bold" icon="arrow-down-1" />
                    }
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        <Table size="sm">
                            <tbody>
                            {props.values?.map((property, key) => {
                                if (property && -1 === ['x','y','z'].indexOf(property[0])) {
                                    return (
                                        <tr key={key}>
                                            <td className="gaia-td-align-right cell-width">
                                                {property[0]} :
                                            </td>
                                            <td className="gaia-td-align-left cell-width">
                                                {property[1]}
                                            </td>
                                        </tr>
                                    );
                                }
                            })}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
        <Accordion defaultActiveKey="0">
            <Card>
                <Accordion.Toggle
                    as={Card.Header}
                    eventKey="0"
                    onClick={() => setPltOpen(!pltOpen)}
                >
                    {t("asset:synthesis.plt")}
                    {detailOpen ?
                        <SzIcon variant="bold" icon="arrow-up-1" /> :
                        <SzIcon variant="bold" icon="arrow-down-1" />
                    }
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        <Table size="sm">
                            <tbody>
                            {props.values?.map((property, key) => {
                                if (property && -1 !== ['x','y','z'].indexOf(property[0])) {
                                    return (
                                        <tr key={key}>
                                            <td className="gaia-td-align-right cell-width">
                                                {property[0]} :
                                            </td>
                                            <td className="gaia-td-align-left cell-width">
                                                {property[1]}
                                            </td>
                                        </tr>
                                    );
                                }
                            })}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>

    </>
  );
};

export default DetailsTableAccordion;
